import { HttpErrorResponse } from '@angular/common/http';

export function extractErrorMessage(error: HttpErrorResponse): string {
  /* Some API errors look like this
    {
      "$.classId": [
        "The JSON value could not be converted to System.Int32. Path: $.classId | LineNumber: 0 | BytePositionInLine: 49."
      ]
    }
    This code is an attemp to parse and display it
   */
  if (Object.keys(error.error ?? {}).some((key) => key.startsWith('$'))) {
    return Object.values(error.error).join('\n');
  }

  return error?.error?.['error_description'] ||
    error?.error?.['error'] ||
    error?.error?.['title'] ||
    error?.error ||
    error?.message ||
    $localize`Error Occurred`;
}
